@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

@font-face{
    font-family:"ff-angie-pro";
    src:url("../fonts/ff-angie-pro-bold.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
}

@font-face{
    font-family:"ff-angie-pro";
    src:url("../fonts/ff-angie-pro-semibold.otf") format("opentype");
    font-weight: 600;
    font-display: swap;
}

@import 'variables.scss';
@import 'mixins.scss';
@import 'global.scss';
@import 'ui.scss';
@import 'layout.scss';
@import 'pages.scss';
@import 'animation.scss';
@import 'helpers';