._nav-list{
    padding-left: 0;
}

._nav-list-item{
    list-style: none;
}

._nav-link{
    font-weight: 300;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1.6px;
    line-height: 18px;
}

._button{
    background-color: transparent;
    border-width: 1px;
    // height: 40px;
    min-width: 100px;
    display: inline-block;
    text-align: center;
    padding: 9px 20px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    &._button-1{
        color: #fff;
        border: 1px solid #fff;
    }
    &._button-2{
        color: #fff;
        border: 1px solid $secondaryColor;
        background-color: $secondaryColor;
    }
    &._button-3{
        color: $secondaryColor;
        border: 1px solid $lightColor;
    }
}

._button-2-large{
    color: $secondaryColor;
    // border: 2px solid $lightColor;
    background-color: $lightBackgroundColor;
    border-radius: 7px;
    padding: 30px 40px;
    font-size: 24px;
    display: block;
    text-align: center;
    @include transition;
    &:hover{
        background-color: $lightColor;
    }
}

._readmore{
    color: $secondaryColor;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:after{
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-right: 1px solid $lightColor;
        border-top: 1px solid $lightColor;
        transform: rotate(135deg);
        position: absolute;
        right: -15px;
        top: 3px;
        @include transition;
    }
    &._up{
        &:after{
            transform: rotate(-45deg);
            top: 10px;
        }
    }
}

._form-row{
    @media(min-width: $tablet){
        display: flex;
        column-gap: 30px;
        flex-wrap: wrap;
    }    
    ._form-group{
        width: 100%;
        margin: 20px 0;
        ._form-element{
            width: 100%;
        }
    }
    ._button{
        margin-top: 30px;
    }
}

._menu-button{
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    span{
        position: absolute;
        left: 0;
        background-color: $secondaryColor;
        width: 100%;
        height: 2px;
        @include transition(0.25s);
        &:nth-of-type(1){
            top: 15px;
        }
        &:nth-of-type(2){
            bottom: 15px;
        }
    }
    @media(min-width: $laptop){
        &:hover{
            span{
                &:nth-of-type(1){
                    top: 12px;
                }
                &:nth-of-type(2){
                    bottom: 12px;
                }
            }
        }
    }
}

._menu-close-button{
    position: relative;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    span{
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: $secondaryColor;
        width: 80%;
        height: 2px;
        @include transition(0.25s);
        &:nth-of-type(1){
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-of-type(2){
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    @media(min-width: $laptop){
        &:hover{
            span{
                width: 100%;
            }
        }
    }
}

._dropdown{
    &:after{
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        border-right: 1px solid $primaryColor;
        border-bottom: 1px solid $primaryColor;
        transform: rotate(45deg);
        position: absolute;
        right: -13px;
        top: calc(50% - 5px);
        @include transition;
    }
    
}
._dropdown-active{
    ._dropdown{
        &:after{
            transform: rotate(-135deg);
            top: calc(50% - 2px);
        }
    }    
}

._hero-item-container{
    height: 100vh;
    background-position: center;
    background-size: cover;
    ._hero-item-inner-grid{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
    }
    ._msg{
        color: #fff;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
    }
    ._right{
        text-align: right;
    }
    ._left{
        text-align: left;
    }
    ._left, ._right{
        display: none;
        width: 180px;
        @media(min-width:$laptop){
            display: block;
        }
    }
    h1{
        font-weight: 600;
    }
}
._hero-slider{
    .slick-prev, .slick-next{
        display: none!important;
    }
}

._hero-slider-next{
    border: none;
    background-color: transparent;
    color: #fff;
    text-transform: capitalize;
    font-size: 14px;
    position: relative;
    padding:20px;
    cursor: pointer;
    white-space: nowrap;
    @include transition;
    &:after{
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        right: 0;
        top: 24px;
        @include transition;
    }
    &:hover{
        transform: scale(1.1);
        &:after{
            // right: -5px;
        }
    }
}

._hero-slider-previous{
    border: none;
    background-color: transparent;
    color: #fff;
    text-transform: capitalize;
    font-size: 14px;
    padding:20px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    @include transition;
    &:after{
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
        transform: rotate(225deg);
        position: absolute;
        left: 0;
        top: 24px;
        @include transition;
    }
    &:hover{
        transform: scale(1.1);
    }
}

._section-heading{
    color: $secondaryColor;
}

._section{
    background-position: center;
    background-size: cover;
}

img.react-parallax-bgimage{
    max-width: unset;
}

._section-bgimage-side-content{
    padding: 100px 0;
    background-position: right;
    background-color: rgba(255, 255, 255, 0.7);
    @media(min-width:$tablet){
        padding: 120px 0;
        background-color: transparent;
    }
    @media(min-width:$laptop){
        padding: 150px 0;
    }
    ._panel{
        max-width: 470px;
        margin: 0 auto;
    }
    ._column{
        &:nth-of-type(1){
            @media(min-width:$tablet){
                ._panel{
                    margin-left: 0;
                }
            }
        }
        &:nth-of-type(2){
            @media(min-width:$tablet){
                ._panel{
                    margin-right: 0;
                }
            }
        }
    }
    h2{
        margin: 0 0 30px 0;
    }
    ._button {
        margin-top: 14px;
    }
}

._section-page-heading{
    height: 30vw;
    min-height: 350px;
    max-height: 550px;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 0;
    ._overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 0;
    }
    ._grid{
        height: 100%;
        align-items:flex-end;
        position: relative;
        z-index: 1;
    }
    p{
        max-width: 770px;
        margin: 0 auto;
    }
    h1{
        margin: 0;
        font-weight: 400;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    ._vertical-line{
        margin: 30px auto 0 auto;
    }
    p{
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
}

._vertical-line{
    width: 1px;
    height: 30px;
    background-color: #fff;
}

._section-page-heading-normal{
    padding: 120px 0 0 0;
    text-align: center;
    @media(min-width: $laptop){
        padding: 170px 0 0 0;
    }
}

._section-split-column{
    ._split-grid{
        display: flex;
        flex-wrap: wrap;
        ._split-column{
            width: 100%;
            background-size: cover;
            background-position: center;
            &:nth-of-type(1){
                height: 90vw;
            }
        }
        ._panel{
            margin: 0 auto;
            width: calc(100% - 40px);
            max-width: 560px;
            overflow-y: auto;
            padding: 60px 0;
            @media(min-width: $tablet){
                width: calc(100% - 60px);
                max-height: 80%;
                padding: 0;
            }
            @media(min-width: $laptop){
                width: calc(100% - 80px);
            }
        }
    }
    img{
        max-width: 325px;
        width: auto;
    }
    ._button{
        margin-top: 14px;
    }
    @media(min-width: $tablet){
        ._split-grid{
            ._split-column{
                width: 50%;
                height: 45vw;
                display: flex;
                align-items: center;
                &:nth-of-type(1){
                    height: 45vw;
                }
            }
            &:nth-child(even){
                ._split-column{
                    &:nth-of-type(1){
                        order: 2;
                    }
                }
            }
        }
    }
}

._hr{
    height: 1px;
    width: 100%;
    background-color: $lightBackgroundColor;
}

._section-collection{
    padding: 60px 0;
    text-align: center;
    position: relative;
    h2{
        margin: 0 0 60px 0;
    }
    h3{
        font-weight: 300;
    }
    img{
        margin: 0 auto;
    }
    ._item{
        padding: 0 15px;
    }
    ._background-block{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 320px;
        background-color: $lightBackgroundColor;
        z-index: -1;
    }
    .slick-arrow {
        display: none!important;
    }
    ._arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        &._arrow-previous{
            left: 30px;
            button{
                &:before{
                    content:"";
                    display: block;
                    position: absolute;
                    left: 14px;
                    top: 10px;
                    width: 20px;
                    height: 20px;
                    border-left: 2px solid $secondaryColor;
                    border-top: 2px solid $secondaryColor;
                    transform: rotate(-45deg);
                }
            }
        }
        &._arrow-next{
            right: 30px;
            button{
                &:after{
                    content:"";
                    display: block;
                    position: absolute;
                    right: 14px;
                    top: 10px;
                    width: 20px;
                    height: 20px;
                    border-right: 2px solid $secondaryColor;
                    border-top: 2px solid $secondaryColor;
                    transform: rotate(45deg);
                }
            }
        }
        button{
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            position: relative;
            cursor: pointer;
        }
    }
    .slick-dots{
        position: relative;
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._section-explore-more-products{
    text-align: center;
    padding: 60px 0;
    h2{
        margin: 0 0 60px 0;
    }
    ._grid{
        align-items: center;
        justify-content: center;
        img{
            margin: 0 auto;
        }
    }
    ._brand-logo{
        max-width: 270px;
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._success-message{
    color: green;
}

._error-message{
    color: red;
}

._success-group{
    padding: 15px;
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 15px
}

._error-group{
    padding: 15px;
    border: 1px solid red;
    border-radius: 5px;
    margin-top: 15px
}

._buttons-group{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 30px;
}

._table-button {
    padding: 7px;
    background-color: $lightBackgroundColor;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    min-width: 55px;
    text-align: center;
    @include transition;
    &._table-button-edit{
        color: green;
    }
    &._table-button-delete{
        color: red;
    }
    &:hover{
        background-color: $lightColor;
    }
}

._button-group{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
}

._dashboard-primary-navigation{
    ul{
        padding-left: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        column-gap: 30px;
        flex-wrap: wrap;
        row-gap: 30px;
        li{
            width: 100%;
            max-width: 300px;
        }
    }
    @media(min-width:$tablet){
        ul{
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }
}

._hr-dashboard{
    background-color: $secondaryColorlight;
    height: 1px;
}

._button-submit{
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    &._sending{
        left: 100vw;
    }
}

.slick-list{
    overflow: unset;
}