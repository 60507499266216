._page-content{
    min-height: 80vh;
}

._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
    }
}

._header{
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    color: $primaryColor;
    background-color: #fff;
    @include transition(.5s, .5s);
    ._header-items{
        position: relative;
        height: 100px;
        ._header-item{
            position: absolute;
            &:nth-of-type(1){
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:nth-of-type(2){
                top: 50%;
                right: calc(50% + 120px);
                transform: translateY(-50%);
                display: none;
                @media(min-width: $laptop){
                    display: block;
                }
            }
            &:nth-of-type(3){
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &:nth-of-type(4){
                top: 50%;
                left: calc(50% + 120px);
                transform: translateY(-50%);
                display: none;
                @media(min-width: $laptop){
                    display: block;
                }
            }
            &:nth-of-type(5){
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            > ul{
                list-style: none;
                padding-left: 0;
                margin: 0;
                display: flex;
                column-gap: 60px;
                > li{
                    
                    > a{
                        display: block;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        @media(min-width: $laptop){
                            height: 130px;
                        }
                    }
                }
            }
        }
        @media(min-width: $laptop){
            height: 130px;
        }
    }
    ._socialmedia-icon{
        svg{
            fill: $secondaryColor;
        }
    }
    ._logo{
        svg{
            fill: $primaryColor;
        }
    }
    &.opaque{
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        position: relative;
        margin-bottom: -100px;
        @media(min-width: $laptop){
            margin-bottom: -130px;
        }
        ._socialmedia-icon, 
        ._logo{
            svg{
                fill: #fff;
            }
        }
        ._menu-button{
            span{
                background-color: #fff;
            }
        }
        ._dropdown{
            &:after{
                content: "";
                border-color: #fff;
            }
        }
        
    }
    &.transparent{
        background-color: transparent;
        color: #fff;
        position: relative;
        margin-bottom: -100px;
        @media(min-width: $laptop){
            margin-bottom: -130px;
        }
        a{
            color: #fff;
        }
        ._socialmedia-icon, 
        ._logo{
            svg{
                fill: #fff;
            }
        }
        ._menu-button{
            span{
                background-color: #fff;
            }
        }
        ._dropdown{
            &:after{
                content: "";
                border-color: #fff;
            }
        }
    }
    // &._hide{
    //     top: -200px;
    // }
}

._header-transformed{
    position: fixed;
    z-index: 1;
    top: -200px;
    left: 0;
    width: 100%;
    color: $primaryColor;
    background-color: #fff;
    @include transition(.5s, .5s);
    ._header-items{
        position: relative;
        height: 100px;
        ._header-item{
            position: absolute;
            &:nth-of-type(1){
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:nth-of-type(2){
                top: 50%;
                right: calc(50% + 120px);
                transform: translateY(-50%);
                display: none;
                @media(min-width: $laptop){
                    display: block;
                }
            }
            &:nth-of-type(3){
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &:nth-of-type(4){
                top: 50%;
                left: calc(50% + 120px);
                transform: translateY(-50%);
                display: none;
                @media(min-width: $laptop){
                    display: block;
                }
            }
            &:nth-of-type(5){
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            > ul{
                list-style: none;
                padding-left: 0;
                margin: 0;
                display: flex;
                column-gap: 60px;
                > li{
                    
                    > a{
                        display: block;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        @media(min-width: $laptop){
                            height: 130px;
                        }
                    }
                }
            }
        }
        @media(min-width: $laptop){
            height: 130px;
        }
    }
    ._socialmedia-icon{
        svg{
            fill: $secondaryColor;
        }
    }
    ._logo{
        svg{
            fill: $primaryColor;
        }
    }
    &._show{
        top: 0px;
    }
}

._header-slideopen{
    ._header-items{
        ._header-item{
            &:nth-of-type(2){
                @media(min-width: $laptop){
                    display: none;
                }
            }
            &:nth-of-type(4){
                @media(min-width: $laptop){
                    display: none;
                }
            }
        }
    }
}

._logo{
    svg{
        width: 80px;
    }
    @media(min-width: $laptop){
        svg{
            width: 100px;
        }
    }
}

._menu{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 2;
    @include transition(.5s, .25s);
    overflow-y: auto;
    &._show{
        left: 0;
        opacity: 1;
        visibility: visible;
        ._nav-slideopen{
            left: 0;
        }
    }
    ._header{
        position: absolute;
    }
}

._nav-slideopen{
    width: 100%;
    margin-top: 100px;
    position: relative;
    left: 200px;
    text-align: center;
    @include transition(.5s,.25s);
    ul{
        padding-left: 0;
        padding: 50px 0;
        li{
            list-style: none;
            a{
                font-size: 25px;
                display: block;
                padding: 10px 0;
            }
        }
    }
    @media(min-width: $laptop){
        margin-top: 120px;
    }
}

._header-item{
    > ul{
        > li{
            position: relative;
            @include transition;
            &._dropdown-active{
                // background-color: $primaryColor;
                // color: #fff;
            }
            > ul{
                position: absolute;
                background-color: $primaryColor;
                color: #fff;
                padding-left: 0;
                list-style: none;
                top: calc(100% - 20px);
                left: -3px;
                padding: 14px 0;
                visibility: hidden;
                opacity: 0;
                @include transition(.25s, .25s);
                &._show{
                    visibility: visible;
                    opacity: 1;
                    top: 100%;
                }
                > li{
                    > a{
                        padding: 7px 20px;
                        white-space: nowrap;
                        display: block;
                        text-transform: uppercase;
                        @include transition(.15s);
                        &:hover{
                            color: $lightColor!important;
                        }
                    }
                }
            }
        }
    }
}

._footer{
    background-color: $primaryColor;
    color: #fff;
    padding: 70px 0 0 0;
    ._footer-logo{
        svg{
            width: 170px;
            fill: #fff;
        }
    }
    ._socialmedia-icon{
        @media(min-width: $tablet){
            text-align: right;
        }
        svg{
            fill: #fff;
        }
    }
}

._footer-items{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    flex-wrap: wrap;
    ._footer-item{
        width: 100%;
        ul{
            padding-left: 0;
            list-style: none;
            li{
                font-weight: 300;
                font-size: 16px;
                a{
                    display: block;
                    padding-bottom: 10px;
                }
            }
        }
        p{
            font-size: 14px;
        }
    }
    
    &:last-child{
        align-items: center;
    }
    @media(min-width:$tablet){
        ._footer-item{
            width: calc(50% - 60px);
        }
    }
    @media(min-width:$laptop){
        flex-wrap: nowrap;
        &:nth-of-type(1){
            padding-bottom: 0;
            ._footer-item{
                width: auto;
            }
        }
        &:last-child{
            padding-top: 0;
            ._footer-item{
                width: 30%;
                &:nth-of-type(1){
                    text-align: left;
                }
                &:nth-of-type(2){
                    text-align: center;
                }
                &:nth-of-type(3){
                    text-align: right;
                }
            }
        }
    }
}

// admin
._dashboard-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    ._menu-icon._close {
        ._hamburger-icon{
            div{
                span{       
                    @include transition;
                    &:nth-of-type(1){
                        top:50%;
                        transform:translateY(-50%) rotate(45deg);
                    }
                    &:nth-of-type(2){
                        top:50%;
                        transform:translateY(-50%) rotate(-45deg);
                    }
                }
            }         
        }
    }
    @media(min-width: $tablet){
        height: 70px;
    }
    @media(min-width: $laptop){
        height: 80px;
    }
    ._item{
        height: 100%;
    }
    a{
        display: inline-block;
    }
    ._dashboard-list{
        height: 100%;
        display: flex;
        margin: 0;
        align-items: center;
        padding-left: 0;
        p{
            margin-top: 0;
        }
        li{
            list-style: none;
            &._breadcrums{
                display: none;
                p{
                    margin: 0;
                }
                a{
                    font-weight: 500;
                    color: $primaryColorLighter;
                    &:hover{
                        color: $primaryColor;
                    }
                }
                @media(min-width: $laptop){
                    display: flex;
                }
            }
            
        }
        > li{
            &:nth-of-type(1){
                padding: 10px 15px 10px 30px;
            }
            &:last-child{
                padding: 10px 30px 10px 15px;
            }
            
            @media (min-width:$tablet){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px 10px 15px;
                }
            }
            @media (min-width:$laptop){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px;
                }
            }
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width:$laptop){
                &._menu-icon{
                    display: none;
                }
            }
            > ul {
                position: absolute;
                top: 100%;
                right: 20px;
                a{
                    display: block;
                    padding: 7px 0;
                }
                background-color: $secondaryColor;
                color: #fff;
                padding: 10px 20px;
                visibility: hidden;
                opacity: 0;
                @include transition;
                border-radius: 5px;
                min-width: 150px;
                > li{
                    padding: 0;
                    
                }
                &._show{
                    visibility: visible;
                    opacity: 1;
                }
                &:before{
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: $secondaryColor;
                    position: absolute;
                    top: -7px;
                    right: 14px;
                    transform: rotate(45deg);
                }
            }
        }
    }
    ._logo-li{
        text-align: center;
        svg{
            fill: $primaryColor;
            width: 40px;
            margin: 0 auto;
        }
        @media (min-width: $laptop) {
            width: 200px;
            text-align: center;
            background-color: $secondaryColor;
            a{
                margin: 0 auto;
            }
            svg{
                fill: #fff;
                width: 60px;
                margin: 0 auto;
            }
        }
    }
    ._logo{
        width: 60px;
        display: block;
        svg{
            width: 100%;
        }
        @media (min-width: $tablet){
            width: 70px;
        }
        @media (min-width: $laptop){
            width: 80px;
        }
    }
}

._dashboard-side-menu{
    position: fixed;
    top: 60px;
    left: -200px;
    color: #fff;
    width: 200px;
    bottom: 0;
    @include transition;
    z-index: 1;
    @media(min-width: $tablet){
        top: 70px;
        bottom: 0;
    }
    @media(min-width: $laptop){
        top: 80px;
        bottom: 0;
        left: 0;
    }
    ._overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        @include transition;
        cursor: pointer;
        @media(min-width: $laptop){
            display: none;
        }
    }
    &._show{
        left: 0;
        ._overlay{
            visibility: visible;
            opacity: 1;
        }
    }
    ._list{
        margin: 0;
        height: 100%;
        background-color: $secondaryColor;
        overflow-y: auto;
        padding-left: 0;
        ul{
            background-color: $secondaryColorDark;
        }
        li{
            list-style: none;
            position: relative;
            
            a{
                display: block;
                padding: 12px 30px;
            }
            >ul{
                display: none;
                padding: 12px 0;
                a{
                    padding: 2px 30px;
                }
                &._show{
                    display: block;
                }
            }
        }
        ._arrow{
            &:after{
                content: "";
                position: absolute;
                top: 20px;
                right: 30px;
                display: block;
                width: 7px;
                height: 7px;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
                @include transition;
            }
            &._up{
                &:after{
                    transform: rotate(-135deg);
                }
            }
        }
    }
}

._dashboard-content{
    margin-top: 80px;
    width: 100%;
    @include transition;
    padding-bottom: 100px;
    &._slide{
        margin-left: 200px;
    }
    @media(min-width: $tablet){
        margin-top: 90px;
    }
    @media(min-width: $laptop){
        margin-top: 100px;
        padding-left: 200px;
        &._slide{
            margin-left: 0;
        }
    }
    ._section{
        padding-left: 30px;
        padding-right: 30px;
    }
}

._section-header{
    ._breadcrumbs{
        font-size: 14px;
        color: $primaryColor;
        @media(min-width: $laptop){
            display: none;
        }
        a{
            @include transition;
            color: $primaryColor;
            &:hover{
                color: $primaryColor;
            }
        }
    }
    > h1{
        padding-bottom: 60px;
        padding-top: 20px;
        margin: 0;
    }
}

._form-dashboard{
    max-width: 650px;
    ._form-row{
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
}

._section-body{
    img{
        margin: 10px;
    }
}