._section-brand-logos{
    text-align: center;
    padding: 40px 0 40px 0;
    ._grid{
        align-items: center;
    }    
    ._section-heading{
        margin: 0 0 30px 0;
    }
    img{
        max-width: 270px;
        margin: 30px auto;
        width: 100%;
        &._brand-cecil{
            width: 160px;
            margin: 60px auto 30px auto;
        }
        &._brand-ww{
            max-width: 100px;
        }
        &._brand-pnp{
            max-width: 225px;
        }
        &._brand-spar{
            max-width: 260px;
        }
    }
    @media(min-width: $tablet){
        padding: 70px 0 50px 0;
        img{
            &._brand-cecil{
                margin: 0 auto;
            }
        }
    }
    @media(min-width: $laptop){
        padding: 70px 0 70px 0;
        ._grid{
            
            flex-wrap: nowrap;
            justify-content: space-between;
            ._column{
                width: auto;
                text-align: center;
            }
        }
    }
}

._section-retail-logos{
    padding: 70px 0;
    img{
        margin: 15px 0;
    }
}

._section-recipes{
    text-align: center;
    padding: 100px 0;
    h2{
        margin: 0;
    }
    h3{
        margin: 30px 0 16px 0;
    }
    ._grid{
        row-gap: 60px;
    }
    ._panel{
        padding: 0 0 60px 0;
        position: relative;
        ._button{
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            @media(min-width: $tablet){
                display: inline-block;
            }
        }
    }
    ._recipe-button{
        margin-top: 14px;
    }
}

._section-pre-footer{
    background-color: $lightBackgroundColor;
    padding: 100px 0;
    ._grid{
        row-gap: 30px;
    }
    h2{
        margin-top: 0;
        margin-bottom: 14px;
    }
    img{
        width: 170px;
    }
    @media(min-width: $tablet){
        ._copy{
            max-height: 160px;
            overflow-y: auto;
        }
    }
}

._section-timeline{
    ._item{
        padding: 60px 0;
        position: relative;
        border-top: 1px solid $lightColor;
        @include transition;
        ._readmore{
            display: none;
        }
        @media(min-width: $tablet){
            height: 300px;
            ._readmore{
                display: inline-block;
            }
            ._copy{
                height: 0px;
                overflow: hidden;
                @include transition;
            }
            ._left{
                ._panel{
                    visibility: hidden;
                    opacity: 0;
                }
            }
            &._open{
                height: 500px;
                ._copy{
                    height: 220px;
                    overflow-y:auto;
                }
                ._left{
                    ._panel{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        
    }
    ._year{
        position: absolute;
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 100px;
        border: 1px solid $lightColor;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-family: $font;
        font-size: 25px;
        color: $secondaryColor;
    }
    ._grid{
        height: 100%;
        align-items: center;
        ._column{
            height: 100%;
            &._left{
                ._panel{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    // height: 100%;
                    @include transition;
                    ._year-background{
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: rotate(-90deg) translate(24%, -69%);
                        font-family: $font;
                        font-size: 150px;
                        color: #E4E6EA;
                        font-weight: 500;
                        z-index: -1;
                    }
                    img{
                        width: 100%;
                        max-width: 370px;
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }
}

._section-about-vinegar{
    padding: 60px 0;
    text-align: center;
    ._panel{
        margin: 0 auto;
        max-width: 800px;
        text-align: center;
    }
    ._grid-3{
        h3{
            margin-top: 10px;
        }
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._section-vinegar-production{
    padding-bottom: 100px;
    // @media(min-width: $laptop){
    //     padding-bottom: 100px;
    // }
}

._section-vinegar-productions-heading{
    padding: 0 0 100px 0;
    text-align: center;
    h2{
        margin: 0;
    }    
    @media(min-width: $tablet){
        padding: 0 0 60px 0;
    }
}

._production-item{
    margin: 100px 0;
    @media(min-width: $tablet){
        margin: 60px 0;
    }
    &:nth-of-type(1){
        margin-top: 0;
    }
    &:last-child{
        margin-bottom: 0;
    }
    ._grid{
        row-gap: 30px;
    }
    h3{
        margin: 0;
        text-transform: uppercase;
    }
    ._logos{
        display: flex;
        align-items: center;
        column-gap: 20px;
        ._item-logo{
            width: auto;
            max-width: 100px;
            max-height: 50px;
        }
    }
    ._column {
        &._left{
            @media(min-width: $tablet){
                border-bottom: 1px solid $lightBackgroundColor;
            }
        }
        &._right{
            ._panel{
                background-color: $lightBackgroundColor;
                display: flex;
                justify-content: space-between;
                padding: 30px;
                column-gap: 20px;
                row-gap: 20px;
                flex-wrap: wrap;
                h5{
                    color: $secondaryColor;
                    margin: 0;
                    white-space: nowrap;
                }
                p{
                    margin:0;
                }
                &:nth-of-type(1){
                    margin-bottom: 15px;
                }
            }
        }
    }
}

._section-exports{
    padding: 60px 0;
    ._panel{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 60px;
        justify-content: center;
        ._panel-item{
            width: 100%;
            text-align: center;
        }
    }
    h3{
        margin: 10px 0;
        font-weight: 300;
    }
    h4{
        font-weight: 300;
        color: $secondaryColor;
        margin: 24px 0 4px 0;
    }
    p{
        margin: 0;
    }
    img{
        max-width: 230px;
    }
    @media(min-width: $tablet){
        ._panel{
            ._panel-item{
                width: calc(50% - 15px);
            }
        }
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._section-food-and-industry{
    padding: 100px 0;
    text-align: center;
    ._grid{
        row-gap: 60px;
    }
    h3{
        margin-top: 0;
    }
    h2{
        margin-top: 0;
    }
    ._item{
        text-align: left;
        h4{
            margin: 0 0 15px 0;
        }
        img{
            max-width: 125px;
        }
    }
}

._section-food-services{
    padding: 0 0 100px 0;
    h2{
        text-align: center;
        margin: 0 0 60px 0;
    }
    h3{
        margin: 10px 0 20px 0;
        font-weight: 300;
    }
    h4{
        font-weight: 300;
        color: $secondaryColor;
        margin: 24px 0 4px 0;
    }
    p{
        margin: 0;
    }
    ._panel{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 60px;
        justify-content: center;
        ._panel-item{
            width: 100%;
        }
    }
    @media(min-width: $tablet){
        ._panel{
            ._panel-item{
                width: calc(50% - 15px);
            }
        }
    }
}

._section-contact-us{
    padding: 0 0 60px 0;
    h2{
        text-align: center;
        margin: 0 0 60px 0;
    }
    h3{
        margin: 10px 0 20px 0;
        font-weight: 300;
    }
    h4{
        font-weight: 300;
        margin: 24px 0 4px 0;
    }
    p{
        margin-bottom: 0;
    }
    ._panel{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 60px;
        justify-content: center;
        ._panel-item{
            width: 100%;
        }
        margin-bottom: 60px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    @media(min-width: $tablet){
        ._panel{
            ._panel-item{
                width: calc(50% - 15px);
                h3{
                    min-height: 70px;
                }
            }
            &:nth-of-type(2){
                ._panel-item{
                    margin-top: 60px;
                    width: 100%;
                }
            }
            &:nth-of-type(2){
                ._panel-item{
                    h3{
                        min-height: unset;
                    }
                }
            }
        }
        
    }
}

._section-contact-careers{
    padding: 0 0 100px 0;
    h2{
        text-align: center;
        margin: 0 0 60px 0;
    }
    h3{
        margin: 10px 0 20px 0;
        font-weight: 300;
    }
    h4{
        font-weight: 300;
        margin: 24px 0 4px 0;
    }
    p{
        margin-bottom: 0;
    }
    ._panel{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 60px;
        justify-content: center;
        ._panel-item{
            width: 100%;
        }
        margin-bottom: 60px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    @media(min-width: $tablet){
        ._panel{
            ._panel-item{
                width: 100%;
            }
            &:nth-of-type(2){
                ._panel-item{
                    width: calc(50% - 15px);
                }
                img{
                    margin-top: 16px;
                    max-width: 200px;
                }
            }
        }
        
    }
}

._section-contact-libstar{
    padding-bottom: 100px;
    text-align: center;
    img{
        max-width: 250px;
    }
}

._section-single-product{
    padding: 60px 0 0 0;
    h2{
        margin-top: 30px 0 20px 0;
    }
    ._description{
        strong{
            color: $secondaryColor;
        }
    }
    ._copy{
        margin: 20px 0;
        p{
            &:nth-of-type(1){
                margin-top: 0;
            }
        }
    }
    ._tabs{
        display: flex;  
        justify-content: space-evenly;
        cursor: pointer;
        ._tab{
            color: #DEE2E6;
            &._active{
                color: $secondaryColor;
            }
        }
        h3{
            margin: 0 0 20px 0;
        }
    }
    ._displays{
        padding: 5px 0;
    }
    ._product-image{
        padding: 60px 30px;
    }
    ._get-in-touch{
        margin-top: 60px;
    }
    @media(min-width: $tablet){
        ._product-image{
            padding: 0 ;
        }
        ._grid{
            align-items: center;
            row-gap: 60px;
        }
        ._column{
            &:nth-of-type(2){
                order: -1;
            }
            &:nth-of-type(3){
                width: 100%;
            }
        }
        ._copy{
            max-height: 150px;
            overflow-y: auto;
        }
        ._panel{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        ._display{
            max-height: 250px;
            overflow-y: auto;
        }
    }
    @media(min-width: $laptop){
        padding: 100px 0 0 0;
        ._grid{
            align-items: unset;
            row-gap: 60px;
        }
        ._column{
            &:nth-of-type(2){
                order: 0;
            }
            &:nth-of-type(3){
                width: calc(33.33% - 30px);
            }
        }
        ._tabs{
            justify-content: space-between;
        }
    }
    
}

._get-in-touch{
    background-color: $secondaryColor;
    color: #fff;
    padding: 20px 30px;
    text-align: center;
    max-width: 370px;
    margin: 0 auto;
    h3{
        margin: 0 0 15px 0;
    }
    ._button{
        display: block;
    }
}

._section-contact-form{
    ._panel{
        max-width: 800px;
        margin: 0 auto 100px auto;
    }
    ._form-row{
        flex-wrap: nowrap;
    }
}

._section-about-us-intro{
    ._panel{
        margin: 0 auto;
        padding: 60px 0;
        max-width: 800px;
        text-align: center;
        h2{
            margin-top: 0;
        }
    }
    @media(min-width: $laptop){
        ._panel{
            padding: 100px 0;
        }
    }
}

._section-signin{
    padding: 0 0 100px 0;
}

._hero-slider{
    overflow: hidden;
}