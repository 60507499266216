@mixin transition($speed: 0.25s, $delay: 0s){
    transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition: $speed;
    -o-transition: $speed;
    -moz-transition: $speed;
    -webkit-transition: $speed;
    transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
}

@mixin boxLayout{
    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 1300px;
    @media(min-width:$tablet){
        width: calc(100% - 30px);
    }
    @media(min-width:$tablet){
        width: calc(100% - 40px);
    }
}

@mixin boxLayout2{
    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 870px;
    @media(min-width:$tablet){
        width: calc(100% - 30px);
    }
    @media(min-width:$tablet){
        width: calc(100% - 40px);
    }
}

@mixin columnPadding{
    padding-left: 10px;
    padding-right: 10px;
    @media(min-width:$tablet){
        padding-left: 15px;
        padding-right: 15px;
    }
    @media(min-width:$tablet){
        padding-left: 20px;
        padding-right: 20px;
    }
}

@mixin columnPadding2{
    padding-left: 20px;
    padding-right: 20px;
    @media(min-width:$tablet){
        padding-left: 30px;
        padding-right: 30px;
    }
    @media(min-width:$tablet){
        padding-left: 40px;
        padding-right: 40px;
    }
}

@mixin buttonStyle{
    height: 40px;
    min-width: 140px;
    width: auto;
    display:inline-block;
    border-radius:0;
    border: 1px solid $themeBlack;
    background-color: $themeBlack;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    font-size: 14px;
}

@mixin topBottomPadding{
    padding-top: 60px;
    padding-bottom: 60px;
    @media(min-width: $laptop){
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@mixin bottomPadding{
    padding-bottom: 60px;
    @media(min-width: $laptop){
        padding-bottom: 100px;
    }
}

@mixin topBottomMargin{
    margin-bottom: 60px;
    margin-top: 60px;
    @media(min-width: $laptop){
        margin-bottom: 100px;
        margin-top: 100px;
    }
}

@mixin headerInner{
    @include boxLayout;
    position:relative;
    height: 100%;
    ._item{
        @include columnPadding;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &:nth-of-type(1){
            left: 0;
            display: none;
            @media(min-width: $tablet){
                display: inline-block;
            }
        }
        &:nth-of-type(2){
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &:nth-of-type(3){
            right: 0;
        }
    }
}

@mixin activeClassStyle{
    position: relative;
    &:before{
        content: "";
        display: block;
        width: calc(100% - 40px);
        height: 2px;
        background-color: $themeGold;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform:translateX(-50%);
        opacity: 0;
        @include transition;
    }
    &._active{
        color: $themeBlack;
        &:before{
            opacity: 1;
        }            
    }
}

@mixin boxShadow {
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
}