*, *:before, *:after {
    box-sizing: border-box;
}

body{
    font-family: $font;
    color: $primaryColor;
    margin: 0;
}

button{
    font-family: $font;
}

h1{
    font-family: $themeFont;
    font-weight: 300;
    font-size: 40px;
    line-height: 46px;
    @media(min-width:$tablet){
        font-size: 50px;
        line-height: 58px;
    }
    @media(min-width:$laptop){
        font-size: 60px;
        line-height: 70px;
    }
}

h2{
    font-family: $themeFont;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    font-weight: 600;
    @media(min-width:$tablet){
        font-size: 34px;
        line-height: 34px;
    }
    @media(min-width:$laptop){
        font-size: 40px;
        line-height: 40px;
    }
}

h3{
    font-family: $themeFont;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    @media(min-width:$tablet){
        font-size: 22px;
        line-height: 30px;
    }
    @media(min-width:$laptop){
        font-size: 25px;
        line-height: 35px;
    }
}

h4{
    font-family: $themeFont;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    @media(min-width:$tablet){
        font-size: 19px;
        line-height: 23px;
    }
    @media(min-width:$laptop){
        font-size: 20px;
        line-height: 25px;
    }
}

h5{
    font-family: $font;
    font-size: 16px;
    font-weight: 300;
}

p{
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
}

strong{
    font-weight: 500;
}

a{
    text-decoration: none;
    color: inherit;
}

input, select{
    height: 40px;
    border-radius: 0;
    border: 1px solid $lightColor;
    padding: 0px 5px;
    display: block;
    width: 100%;
}

textarea{
    border-radius: 0;
    border: 1px solid $lightColor;
    padding: 0px 5px;
    display: block;
}

label{
    padding: 0 0 7px 0;      
    display: block;
}

img{
    max-width: 100%;
}